export const ALERT_TYPE = {
  SUCCESS: 0,
  ERROR: 1,
  WARN: 3,
  INFO: 4,
}

export const SYSTEM_ROLES = {
  MT_SQUARE: 1,
  CUSTOMER_MT: 0,
}

export const USER_STATUS = Object.freeze({
  ACTIVE: 1,
  DEACTIVE: 0,
})

export const MEMBER_STATUS = Object.freeze({
  ACTIVE: 1,
  DEACTIVE: 0,
})

export const COMPANY_STATUS = {
  ACTIVE: 1,
  DEACTIVE: 0,
}

export const LOGIN_STATUS = {
  ACTIVE: 1,
  DEACTIVE: 0,
}

export const VIEW_COST = Object.freeze({
  APP: 1,
  DEPARTMENT: 2,
  STAFF: 3,
})

export const SAAS_CONNECTION = {
  DISABLE_LABEL: '-----',
  CONNECTED_LABEL: '従業員に紐づけ',
}

export const FORM_MODE = {
  ADD: 1,
  EDIT: 2,
  VIEW: 3,
}

export const DEFAULT_IMAGE = require('@/assets/images/defaut-image.png')

export const DEFAULT_COMPANY_LOGO = require('@/assets/images/default-company-logo.png')

export const DEFAULT_NAVBAR_PROFILE_IMAGE = require('@/assets/images/default_navbar_profile_image.jpg')

export const MAX_FILE_SIZE = {
  IMAGE: 5 * 1024 * 1024, // 5MB
}

export const FREE_MAIL_LIST = ['gmail.com', 'yahoo.com', 'yahoo.com.vn']

export const MAX_LENGTH = {
  DEFAULT: 255,
  PHONE_NUMBER: 15,
  PRICE: 11,
  COUNT_USER: 4,
}

export const PASSWORD_DUMMY = 'password-dummy' // this not the real password, display purpose only

export const ROLE_TYPES = {
  ADMIN: 1,
  CUSTOMER: 2,
}

export const SWITCH_LOGIN = {
  ALLOW: 1,
  DISALLOW: 0,
}

export const SWITCH_LOGIN_LABEL = {
  SYSTEM_LOGIN: '管理者としてログイン',
  CUSTOMER_LOGIN: '顧客としてログイン',
}

export const START_YEAR = 2021

export const SERVICE_STATUS = {
  MAINTAIN: 0,
  ACTIVE: 1,
}

export const INTEGRATE_STATUS = {
  NOT_CONNECTED: 0,
  AUTO_CONNECTED: 1,
  MANUAL_CONNECTED: 2,
}

export const SYNC_STATUS = [
  {
    id: 1,
    text: '自動',
  },
  {
    id: 0,
    text: '手動',
  },
]

export const NOT_LINKING_SAAS = '未紐づけ'

// by pass the cors problems
// using different server if needed
export const CORS_EVERY_WHERE_URL =
  process.env.VUE_APP_CORS_EVERY_WHERE_URL || 'https://whispering-badlands-10752.herokuapp.com/'

export const STATUS_CODE = {
  OK: 200,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  BAD_REQUEST: 400,
  UNKNOWN_ERROR: 520,
}

export const OWNER = {
  OK: 1,
  KO: 0,
}

export const CURRENT_YEAR = new Date().getFullYear()

export const USING_STATUS = {
  USE: 1,
  NOT_USE: 2,
}
