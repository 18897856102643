import Vue from 'vue'
// only import what we will use
import {
  BreadcrumbPlugin,
  ButtonPlugin,
  ButtonGroupPlugin,
  DropdownPlugin,
  TablePlugin,
  ModalPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  InputGroupPlugin,
  FormSelectPlugin,
  PaginationPlugin,
  SpinnerPlugin,
  FormCheckboxPlugin,
} from 'bootstrap-vue'

Vue.use(BreadcrumbPlugin)
Vue.use(ButtonPlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(DropdownPlugin)
Vue.use(TablePlugin)
Vue.use(ModalPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormPlugin)
Vue.use(InputGroupPlugin)
Vue.use(FormSelectPlugin)
Vue.use(PaginationPlugin)
Vue.use(SpinnerPlugin)
Vue.use(FormCheckboxPlugin)
