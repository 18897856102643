export const MESSAGES = {
  COMMON: {
    MSG01: '保存しました。', // create success
    MSG02: '保存しました。', // update success
    MSG05: '削除しました。', // delete success
    MSG07: 'パスワードは英大文字・英小文字・数字・記号を含め、8文字以上です。', // password policy
    MSG08: '必須項目です。', // required
    MSG09: 'xxx@xxx.xx形式で入力してください。', // format email
    MSG10: '有料のメールが必要です。', // not free mail
    MSG15: 'エラーが発生しました。', // unknown error
    MSG23: 'JPGもしくはJPEGもしくはPNG形式でアップロードしてください。', // image file type
    MSG99: '５MB以下のイメージだけアップロードしてください。', // > 5MB error
  },
  ADMIN_MT: {
    ADM01: 'サービス名が既に登録されています。', //app name existed
    ADM02: '会社名が既に登録されています。', // company name existed
    ADM03: 'ドメインが既に登録されています。', // domain existed
    ADM04: 'メールアドレスが既に登録されています。', // email existed
    ADM05: '内部メンバーが既に登録されています。', // internal memeber existed
    ADM06: 'プラン名が既に登録されています。', // package name existed
    ADM07: 'アカウントが既に登録されています。', // email created, account mt
    ADM08: '現在のパスワードが間違っています。', // wrong password
    ADM09: '直前の項目と同じ内容を入力してください。', // password not match
    ADM10: '新しいパスワードは現在のパスワードと同じです。', // new password match old password
    ADM11: 'プラン有効期間が重複されています。', // period package duplicated
    ADM99: '開日は終了日より前である必要があります。', // date check
  },
  CUSTOMER_MT: {
    KH02: '契約が既に登録されています。', // contract existed
    KH03: '契約有効期間は同じサービスと重複されています。', // contract timeline
    KH04: '部署IDが既に登録されています。', // code existed
    KH05: '部署名が既に登録されています。', // name existed
    KH06: '役職IDが既に登録されています。', // position code existed
    KH07: '役職名が既に登録されています。。', // position name existed
    KH08: '従業員IDが既に登録されています。', // user_code existed
    KH09: 'メールアドレスが既に登録されています。', // email existed
  },
  SAAS_CONNECT: {
    LK01: '連携しました。', // saas connect successfully
    LK02: '連携を解除してもよろしいですか。', // disconnect saas confirm
    LK03: '連携解除しました。', // saas disconnect successfully
    LK04: '取り込みました。', // fetch successfully
    LK05: '削除しました。', // delete account successfully
    LK06: '作成しました。', // create account successfully
    LK07: '招待しました。', // invite account successfully
    LK08: '紐づけしました。', // linking account successfully
    LK09: '請求情報取得しました。', // get costs successfully
    LK10: 'ドメインはまだ作成されていません。', //domain not created
    LK11: '管理者が削除できません。', //Delete admin error
    LK12: '現在のプランではこれ以上SaaSを連携できません。', // exceed the allowed plan's amount
    SF01: 'このプロフィールIDはライセンス数が上限に達したため、アカウント作成できません。', // limit license
    SF02: 'メールアドレスが既に登録されています。', // duplicate username
    SF03: 'FIELD_INTEGRITY_EXCEPTION。', // FIELD_INTEGRITY_EXCEPTION
    SF04: 'パスワードには、1つの英字と1つの数字が含まれる8文字以上の文字を使用する必要があります。', // password policy salesforce
    FR01: '事業所設定を完了させて下さい。', // company basic settings
    KT01: 'Administrators cannot be deleted completely', // Delete admin kintone
    JR01: 'You cannot delete the currently logged in user.', // Delete admin jira
  },
}
