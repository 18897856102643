import axios from 'axios'
import store from '@/store/store'

import {
  convertToFormUrlencodedData,
  isObjectAndNotFormData,
  clearObjectValue,
} from '@/utils/object-helpers'
import { STATUS_CODE } from '@/utils/constants'

const internalApiAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000,
})

const currentExecutingRequests = {}
let loadingRequestCount = 0

function showRequestLoading() {
  if (loadingRequestCount === 0) {
    store.dispatch('loading/showLoading').then()
  }
  loadingRequestCount++
}

function hideRequestLoading() {
  if (loadingRequestCount <= 0) {
    return
  }
  loadingRequestCount--
  if (loadingRequestCount === 0) {
    setTimeout(() => {
      store.dispatch('loading/hideLoading').then()
    }, 100)
  }
}

//Add a request interceptor
internalApiAxios.interceptors.request.use(
  (config) => {
    if (currentExecutingRequests[config.url]) {
      // if the current request is not completed, cancel the requests which have same url
      const src = currentExecutingRequests[config.url]
      delete currentExecutingRequests[config.url]
      src.cancel()
    }
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    config.cancelToken = source.token
    currentExecutingRequests[config.url] = source

    // add request token
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    config.transformRequest = [
      function (data, headers) {
        if (headers.custom && headers['Content-Type']) {
          headers[config.method]['Content-Type'] = headers['Content-Type']
        }
        // change objects data to x-www-form-urlencoded
        if (isObjectAndNotFormData(data) && !headers.custom) {
          headers['Content-Type'] = 'application/x-www-form-urlencoded'
          data = convertToFormUrlencodedData(data)
        }
        return data
      },
    ]

    showRequestLoading()
    return config
  },
  (error) => {
    Promise.reject(error).then()
  }
)
//Add a response interceptor
internalApiAxios.interceptors.response.use(
  function (response) {
    hideRequestLoading()
    if (currentExecutingRequests[response.request.responseURL]) {
      // here you clean the request
      delete currentExecutingRequests[response.request.responseURL]
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    hideRequestLoading()
    const { config } = error
    const originalRequest = config

    if (axios.isCancel(error)) {
      // here you check if this is a cancelled request to drop it silently (without error)
      return new Promise(() => {
        return {}
      })
    }

    if (currentExecutingRequests[originalRequest.url]) {
      // here you clean the request
      delete currentExecutingRequests[originalRequest.url]
    }
    //status forbidden 403 or 401
    if (
      error.message.indexOf(STATUS_CODE.FORBIDDEN) !== -1 ||
      error.message.indexOf(STATUS_CODE.UNAUTHORIZED) !== -1
    ) {
      store.dispatch('user/logout').then(() => {
        clearObjectValue(currentExecutingRequests)
      })
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

export default internalApiAxios
