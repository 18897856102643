import axios from '../../configs/http/internal-api-axios'
import { extendMasterRead } from '@/utils/function-utils'

async function login(domain, email, password) {
  try {
    const response = await axios.post('/api/v1/users/login', { domain, email, password })
    if (response.data) {
      const { access_token, user } = response.data
      localStorage.setItem('token', access_token)
      localStorage.setItem('user', JSON.stringify(user))
      return user
    }
    return false
  } catch (err) {
    return false
  }
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user')
  localStorage.removeItem('token')
}

async function updateInfo(formData) {
  const url = 'api/v1/mt/users/info'
  const response = await axios
    .patch(url, formData)
    .then((res) => {
      return {
        success: true,
        data: res.data,
      }
    })
    .catch((err) => {
      return {
        success: false,
        data: err.response.data.message,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

async function get(isMaster = false) {
  let url = 'api/v1/mt/users/info'
  url = extendMasterRead(isMaster, url, '?')
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data
}

async function getCustomerInfo(userId, isMaster = false) {
  if (!userId) {
    return
  }
  let url = `api/v1/user/${userId}`
  url = extendMasterRead(isMaster, url, '?')
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data
}

async function getCompanyByCompanyId(companyId, isMaster = false) {
  if (!companyId) {
    return
  }
  let url = `api/v1/setting/company/${companyId}`
  url = extendMasterRead(isMaster, url, '?')
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data
}

async function updateCompanySetting(companyId, formData) {
  const url = `api/v1/setting/company/${companyId}`
  const response = await axios
    .patch(url, formData)
    .then((resp) => {
      return {
        success: true,
        data: resp.data,
      }
    })
    .catch((e) => {
      return {
        success: false,
        data: e.response.data.errors,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

async function updateUserSetting(userId, formData) {
  const url = `api/v1/setting/user/${userId}`
  const response = await axios
    .patch(url, formData)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      }
    })
    .catch((evn) => {
      return {
        success: false,
        data: evn.response.data.message,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

async function changePassword(formData, options = {}) {
  let url = 'api/v1/setting/change-password'
  if (options.id) {
    url = `api/v1/reset-password/${options.id}`
  }
  const response = await axios
    .patch(url, formData)
    .then((result) => {
      return {
        success: true,
        data: result.data,
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error.response.data.message,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

export const userService = {
  login,
  logout,
  updateInfo,
  get,
  getCustomerInfo,
  getCompanyByCompanyId,
  updateCompanySetting,
  updateUserSetting,
  changePassword,
}
