import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import Fragment from 'vue-fragment'
import Multiselect from 'vue-multiselect'
import router from '@/router/index'
import App from '@/App.vue'
import '@/styles/app.css'
import store from '@/store/store'
import '@/configs/boostrap-vue'
import axios from '@/configs/http/internal-api-axios'
import i18n from '@/configs/multi-lang/i18n'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(Fragment.Plugin)
Vue.component('Multiselect', Multiselect)
Vue.component('Apexchart', VueApexCharts)

new Vue({
  router,
  store,
  axios,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
