import axios from '@/configs/http/internal-api-axios'

async function getCommonServices() {
  const url = `/api/v1/settings`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data
}

export const commonService = {
  getCommonServices,
}
