import { getRandomKey } from '@/utils/function-utils'
import { MESSAGES } from '@/utils/messages'
import { ALERT_TYPE } from '@/utils/constants'

export const state = {
  items: [],
  keys: [],
}

export const getters = {
  keys: (state) => state.keys,
  items: (state) => state.items,
}

export const mutations = {
  displayAlert(state, newValue) {
    const { key } = newValue
    state.keys.push(key)
    state.items.push({ ...newValue })
  },

  hideAlert(state, key) {
    const removeItem = state.items.filter((d) => d.key === key)[0]
    state.items.splice(state.items.indexOf(removeItem), 1)
    state.keys.splice(state.keys.indexOf(key), 1)
  },
}

export const actions = {
  displayAlert({ commit }, { type, messages, time }) {
    const key = getRandomKey()
    if (type === ALERT_TYPE.ERROR && !messages) {
      messages = MESSAGES.COMMON.MSG15
    }
    commit('displayAlert', { type, messages, key })
    setTimeout(() => {
      commit('hideAlert', key)
    }, time || 2500)
  },
  hideAlert({ commit }, { key }) {
    commit('hideAlert', key)
  },
}
