export const state = {
  code: '',
  callbackRouteName: '',
  service: {},
  metadata: {},
  fetchTime: {},
  isEnterprise: false,
  package: 0,
}

export const getters = {
  code: (state) => state.code,
  routePath: (state) => state.callbackRouteName,
  service: (state) => state.service,
  savedMetadata: (state) => state.metadata,
  fetchTime: (state) => state.fetchTime,
  isEnterprise: (state) => state.isEnterprise,
  package: (state) => state.package,
}

export const mutations = {
  setCode(state, code) {
    state.code = code
  },
  setCallbackRoute(state, link) {
    state.callbackRouteName = link
  },
  setService(state, service) {
    state.service = service
  },
  setServiceMetadata(state, metadata) {
    state.metadata = metadata
  },
  setFetchTime(state, fetchTime) {
    state.fetchTime = { ...state.fetchTime, ...fetchTime }
  },
  resetState(state) {
    state.code = ''
    state.callbackRouteName = ''
  },
  resetFetchTime(state, id) {
    state.fetchTime[id] = ''
  },
  setEnterprise(state, type) {
    state.isEnterprise = type
  },
  setPackage(state, value) {
    state.package = value
  },
}
export const actions = {
  resetState({ commit }) {
    commit('resetState')
  },
  setCode({ commit }, code) {
    commit('setCode', code)
  },
  setService({ commit }, service) {
    commit('setService', service)
  },
  setServiceMetadata({ commit }, metadata) {
    if (metadata && process.env.NODE_ENV === 'production') {
      delete metadata.access_token // not save access_token
    }
    commit('setServiceMetadata', metadata)
  },
  setFetchTime({ commit }, fetchTime) {
    commit('setFetchTime', fetchTime)
  },
  resetFetchTime({ commit }, serviceId) {
    commit('resetFetchTime', serviceId)
  },
  setCallbackRoute({ commit }, link) {
    commit('setCallbackRoute', link)
  },
  setEnterprise({ commit }, type) {
    commit('setEnterprise', type)
  },
  setPackage({ commit }, value) {
    commit('setPackage', value)
  },
}
