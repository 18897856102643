export const state = {
  loading: false,
  manualLoading: false, // for better UX
  disableLoading: false,
}

export const getters = {
  loading: (state) => state.loading,
  manualLoading: (state) => state.manualLoading,
  disableLoading: (state) => state.disableLoading,
}

export const mutations = {
  showLoading(state) {
    state.loading = true
  },
  hideLoading(state) {
    state.loading = false
  },
  showManualLoading(state) {
    state.manualLoading = true
  },
  hideManualLoading(state) {
    state.manualLoading = false
  },
  disableLoading(state) {
    state.disableLoading = true
  },
  enableLoading(state) {
    state.disableLoading = false
  },
}
export const actions = {
  showLoading({ commit }) {
    commit('showLoading')
  },
  hideLoading({ commit }) {
    commit('hideLoading')
  },
  showManualLoading({ commit }) {
    commit('showManualLoading')
  },
  hideManualLoading({ commit }) {
    commit('hideManualLoading')
  },
  disableLoading({ commit }) {
    commit('disableLoading')
  },
  enableLoading({ commit }) {
    commit('enableLoading')
  },
}
