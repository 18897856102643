import isObject from 'lodash-es/isObject'

export const convertToFormUrlencodedData = (object) =>
  Object.keys(object).reduce((formData, key) => {
    if (isNotNullOrUndefined(object[key])) {
      formData.append(key, isObject(object[key]) ? JSON.stringify(object[key]) : object[key])
    }
    return formData
  }, new URLSearchParams())

export const isNotNullOrUndefined = (value) => {
  return !(value === null || value === undefined)
}

export const isObjectAndNotFormData = (value) => {
  return typeof value === 'object' && !(value instanceof URLSearchParams)
}

export const clearObjectValue = (obj) => {
  for (const prop of Object.getOwnPropertyNames(obj)) {
    delete obj[prop]
  }
}

export const isHttpLink = (value) => {
  const regex = new RegExp(/^((http[s]?):\/\/)+.*$/)
  return regex.test(value)
}

export const flattenArray = (array, separator = '_') => {
  return array.map((item) => {
    const newObj = {}
    Object.keys(item).forEach((key) => {
      if (isObject(item[key])) {
        Object.keys(item[key]).forEach((innerKey) => {
          newObj[`${key}${separator}${innerKey}`] = item[key][innerKey]
        })
      } else {
        newObj[key] = item[key]
      }
    })
    return newObj
  })
}
