import { userService } from '@/services/auth-service/user-service'
import router from '@/router/index'
import { ROLE_TYPES, SWITCH_LOGIN, SYSTEM_ROLES } from '@/utils/constants'
import isEmpty from 'lodash-es/isEmpty'
import get from 'lodash-es/get'

export const state = {
  user: JSON.parse(localStorage.getItem('user')),
  switched: localStorage.getItem('switched') === 'true',
}

export const getters = {
  currentUser: (state) => state.user || [],
  isSystemUser: (state) => get(state, 'user.system_user', '') === SYSTEM_ROLES.MT_SQUARE,
  switchLoginEnable: (state) => {
    return (
      get(state, 'user.login_accepted', '') === SWITCH_LOGIN.ALLOW &&
      get(state, 'user.system_user', '') === SYSTEM_ROLES.MT_SQUARE
    )
  },
  isSwitched: (state) => state.switched,
  isCustomerUser: (state) =>
    get(state, 'user.system_user', '') !== SYSTEM_ROLES.MT_SQUARE &&
    get(state, 'user.role_id', '') === ROLE_TYPES.CUSTOMER,
  isCustomerAdmin: (state) =>
    get(state, 'user.system_user', '') !== SYSTEM_ROLES.MT_SQUARE &&
    get(state, 'user.role_id', '') === ROLE_TYPES.ADMIN,
}

export const actions = {
  // Logs in the user.
  async login({ dispatch, commit }, { domain, email, password }) {
    const result = await userService.login(domain, email, password)
    if (!result) {
      return result
    }
    commit('loginSuccess', result)
    await router.push(
      result['system_user'] === SYSTEM_ROLES.MT_SQUARE ? '/dashboard' : '/customer/dashboard'
    )
  },
  // Logout the user
  logout({ commit }) {
    userService.logout()
    router
      .push({
        name: 'login',
      })
      .catch(() => {
        return {}
      })
    commit('logout')
  },
  setUser({ commit }, user) {
    commit('switchLogin', localStorage.getItem('switched') === 'true')
    if (user) {
      commit('loginSuccess', user)
      return
    }
    if (isEmpty(this.getters['user/currentUser'])) {
      if (!localStorage.getItem('user')) {
        commit('logout')
      }
      commit('loginSuccess', JSON.parse(localStorage.getItem('user')))
    }
  },
  switchLogin({ commit }, isSwitch) {
    commit('switchLogin', Boolean(isSwitch))
  },
}

export const mutations = {
  loginSuccess(state, user) {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
  },
  logout(state) {
    state.user = null
    localStorage.setItem('switched', 'false')
  },
  switchLogin(state, isSwitch) {
    state.switched = isSwitch
    localStorage.setItem('switched', String(isSwitch))
  },
}
