import { FREE_MAIL_LIST } from '@/utils/constants'

export const handleChangeInput = (timer, func, waitingTime = 500) => {
  if (timer) {
    clearTimeout(timer)
  }
  return setTimeout(() => {
    func && func()
  }, waitingTime)
}

export const getRandomKey = () => {
  return new Date().getTime() + Math.random()
}

export const fileImageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = function () {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const validateFileType = (file, allowFileTypes = []) => {
  const fileType = file.name.split('.').pop().toLowerCase()
  return allowFileTypes.includes(fileType)
}

export const validateFileSize = (file, maxFileSize = 0) => {
  const fileSize = file.size
  return fileSize < maxFileSize
}

export const stringToNumberInput = (str, allowZeroStart = false) => {
  if (str === '') {
    return ''
  }
  if (str === '0') {
    return str
  }
  const value = !allowZeroStart ? Number(str.replace(/\D/g, '')) : str.replace(/\D/g, '')
  if (value === 0 && str.length === 1) {
    return ''
  }
  if (value === 0 && str.length > 1) {
    return '0'
  }

  return value
}

export function notFreeMail(value) {
  const lastWordAfterAtSign = value.split('@').pop()
  return !FREE_MAIL_LIST.includes(lastWordAfterAtSign)
}

export function getParameterByName(link, name) {
  const regex = new RegExp('[\\?&]*' + name + '=([^&#]*)')
  const results = regex.exec(link)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export function convertStringToBase64(str) {
  return Buffer.from(str).toString('base64')
}

export function arrayUniqueByKey(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}

export function extendMasterRead(isMaster, url, prefix = '&') {
  if (!isMaster) {
    return url
  }
  return `${url}${prefix}master=1` // force read data from master node
}
