import { commonService } from '@/services/common-settings-service'

export const state = {
  commonSettings: {},
}

export const getters = {
  roles: (state) => state.commonSettings.roles || [],
  career: (state) => state.commonSettings.career || [],
  mt_packages: (state) => state.commonSettings.mt_packages || [],
  apps_category: (state) => state.commonSettings.apps_category || [],
  user_status: (state) => state.commonSettings.user_status || [],
  user_list_status: (state) => state.commonSettings.user_list_status || [],
  apps: (state) => state.commonSettings.apps || [],
  mt_status: (state) => state.commonSettings.mt_status || [],
  mt_contract_type: (state) => state.commonSettings.mt_contract_type || [],
  login_accepted: (state) => state.commonSettings.login_accepted || [],
  login_accepted_customer: (state) => state.commonSettings.login_accepted_customer || [],
  mt_cost_type: (state) => state.commonSettings.mt_cost_type || [],
  saas_fillter: (state) => state.commonSettings.saas_fillter || [],
}

export const mutations = {
  setCommonSettings(state, newValue) {
    state.commonSettings = newValue
  },
}

export const actions = {
  async fetchCommonSetting({ commit }) {
    const data = await commonService.getCommonServices()
    commit('setCommonSettings', data)
  },
}
