<script>
import appConfig from '@/app.config'
import { mapActions } from 'vuex'

// Prepare backlog
export default {
  name: 'App',
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
  async created() {
    await this.fetchCommonSetting()
    await this.setUser()
  },
  methods: {
    ...mapActions('commonSettings', ['fetchCommonSetting']),
    ...mapActions('user', ['setUser']),
  },
}
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>
