import { userService } from '@/services/auth-service/user-service'

export default [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/dashboard'),
  },
  {
    path: '/customer/dashboard',
    name: 'dashboard-customer',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/dashboard-customer/dashboard-customer'),
  },
  {
    path: '/management-service',
    name: 'management-service',
    meta: {
      authRequired: true,
      systemUser: true,
    },
    component: () => import('../pages/management-service/management-service'),
  },
  {
    path: '/management-service/detail-service',
    name: 'detail-service',
    meta: {
      authRequired: true,
      systemUser: true,
    },
    component: () => import('../pages/management-service/detail-service'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/login/login-page'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: {
      beforeRouteEnter(to, from, next) {
        userService.logout()
        const destination = {
          path: '/login',
        }
        next(destination)
      },
    },
  },
  {
    path: '/internal-member-management',
    name: 'internal-member-management',
    meta: {
      authRequired: true,
      systemUser: true,
    },
    component: () => import('../pages/internal-member-management/internal-member-management.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: require('../pages/utility/404').default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/package-information-management',
    name: 'PackageInformationManagement',
    meta: {
      authRequired: true,
      systemUser: true,
    },
    component: () =>
      import('../pages/package-information-management/package-information-management'),
  },
  {
    path: '/management-member',
    name: 'management-member',
    meta: {
      authRequired: true,
      systemUser: true,
    },
    component: () => import('../pages/management-member/management-member'),
  },
  {
    path: '/management-member/member-detail/:id',
    name: 'management-member-detail',
    meta: {
      authRequired: true,
      systemUser: true,
    },
    component: () => import('../pages/management-member/management-member-detail'),
  },
  {
    path: '/mt-account-setting',
    name: 'mt-account-setting',
    meta: {
      authRequired: true,
      systemUser: true,
    },
    component: () => import('../pages/account-info/account-mt'),
  },
  {
    path: '/customer/contract-list',
    name: 'contract-list',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/contract-list/contract-list'),
  },
  {
    path: '/customer/department-list',
    name: 'department-list',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/department-list/department-list'),
  },
  {
    path: '/customer/department-list/department-detail',
    name: 'department-detail',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/department-list/department-detail'),
  },
  {
    path: '/customer/list-of-cost',
    name: 'list-of-cost',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/list-of-cost/list-of-cost'),
  },
  {
    path: '/customer/list-of-internal-member',
    name: 'list-of-internal-member',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/list-of-internal-member/list-of-internal-member'),
  },
  {
    path: '/customer/management-job',
    name: 'management-job',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/management-job/management-job'),
  },
  {
    path: '/customer/employee-management',
    name: 'employee-management',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/employee-management/employee-management-page'),
  },
  {
    path: '/customer/employee-management/employee-detail/:id',
    name: 'employee-detail',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/employee-detail/employee-detail-page'),
  },
  {
    path: '/customer/setting-info-company',
    name: 'setting-info-company',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/setting-info-company/setting-info-company'),
  },
  {
    path: '/customer/account-setting',
    name: 'account-setting',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/account-setting/account-setting-page'),
  },
  {
    path: '/customer/saas-management',
    name: 'saas-management',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/saas-management/saas-management-page'),
  },
  {
    path: '/customer/saas-management/:id',
    name: 'saas-connection',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/saas-management/saas-connection'),
  },
  {
    path: '/authorize',
    name: 'saas-authorize',
    meta: {
      authRequired: true,
    },
    component: () => import('../pages/saas-management/saas-authorize'),
  },
]
